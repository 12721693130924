import { render, staticRenderFns } from "./ArchivedProjectsList.vue?vue&type=template&id=4069068e&scoped=true&"
import script from "./ArchivedProjectsList.vue?vue&type=script&lang=ts&"
export * from "./ArchivedProjectsList.vue?vue&type=script&lang=ts&"
import style0 from "./ArchivedProjectsList.vue?vue&type=style&index=0&id=4069068e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4069068e",
  null
  
)

export default component.exports