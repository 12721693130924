
import Project from "@/model/Project";
import { TableFields } from "@/model/Tables/TableFields";
/**
 * The list of archive projects in the system this view is only for the admin users
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface CurrentOpen {
  id: number;
  name: "";
}

import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import TableHeader from "@/components/tableHeader.vue";
import SubProjectView from "@/components/project/ProjectList/SubProjectView.vue";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";
import { ActionMessage } from "@/model/Messages/statusMessage";

@Component({
  name: "ArchivedProjectsList",
  components: {
    TableHeader,
    SubProjectView
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("projects", {
      mainArchivedProjects: "GetAllArchivedProjects",
      archiveSubProjectsList: "GetAllArchivedSubProjects",
      CURRENT_PROJECT_OPEN: "GetCurrentProjectOpen",
      CURRENT_MAIN_PROJECT_OPEN: "GetCurrentMainProjectOpen"
    })
  },
  methods: {
    ...mapActions("projects", {
      GET_ALL_ARCHIVED_PROJECTS: "GetAllArchivedProjectsFromBackEnd",
      RESTORE_PROJECT: "restoreProject",
      DELETE_PROJECT: "DeleteProject",
      updateMainProjectOpen: "updateMainProjectOpen",
      updateSubProjectOpen: "updateSubProjectOpen"
    })
  }
})
export default class ArchivedProjectsList extends Mixins(confirmModal) {
  /*----------  Vuex  ----------*/
  mainArchivedProjects!: Array<Project>;
  archiveSubProjectsList!: Function;

  readonly getUserAccess!: UserAccess;
  private readonly CURRENT_PROJECT_OPEN!: CurrentOpen;
  private readonly CURRENT_MAIN_PROJECT_OPEN!: CurrentOpen;

  private GET_ALL_ARCHIVED_PROJECTS!: Function;
  private RESTORE_PROJECT!: Function;
  private DELETE_PROJECT!: Function;

  private updateMainProjectOpen!: Function;
  private updateSubProjectOpen!: Function;
  /*----------  Local Data  ----------*/
  fields: Array<TableFields> = [
    {
      key: "name",
      sortable: true,
      label: this.translateTableLabel("name")
    },
    {
      key: "status",
      sortable: true,
      label: this.translateTableLabel("status")
    },
    {
      key: "dateStart",
      sortable: true,
      label: this.translateTableLabel("dateStart")
    },
    {
      key: "dateEnd",
      sortable: true,
      label: this.translateTableLabel("dateEnd")
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableLabel("actions")
    }
  ];
  updateStatus: null | ActionMessage = null;
  loading = true;
  /*----------  life cycles  ----------*/
  // created get all the archived projects
  async created() {
    await this.GET_ALL_ARCHIVED_PROJECTS();
    this.loading = false;
    this.checkCurrentOpen();
  }

  /*---------- Helper functions   ----------*/
  translateTableLabel(label: string) {
    return this.$t(`projects.tableLabel.${label}`);
  }
  /*---------- Computed   ----------*/

  /*---------- Methods   ----------*/
  checkCurrentOpen() {
    if (!this.CURRENT_MAIN_PROJECT_OPEN) return;
    const $elMainProject: HTMLElement | null = document.querySelector(
      `[data-track-open=project-${this.CURRENT_MAIN_PROJECT_OPEN.id}]`
    );
    if ($elMainProject) {
      $elMainProject?.click();
      setTimeout(() => {
        const $elSubProject: HTMLElement | null = this.CURRENT_PROJECT_OPEN
          ? document.querySelector(`[data-track-open=project-${this.CURRENT_PROJECT_OPEN.id}]`)
          : null;

        $elSubProject ? $elSubProject.click() : null;
      }, 10);
    }
  }

  handleCloseOfTheAlert() {
    // get all the archive devices
  }
  HandleRestoreProject(p: Project) {
    this.confirm(
      this.$t("archiveProjects.actions.confirmRestore", { name: p.name }).toString(),
      async () => {
        this.loading = true;
        let res = await this.RESTORE_PROJECT(p.id);
        if (res.status === 200) {
          this.updateStatus = {
            class: "success",
            msg: this.$t("archiveProjects.actions.restoreSuccess").toString()
          };
          await this.GET_ALL_ARCHIVED_PROJECTS();
          this.checkCurrentOpen();
        } else if (res.response.status === 400) {
          this.updateStatus = {
            class: "warning",
            msg: (this as any).$t("archiveProjects.actions.action400res")
          };
        } else {
          this.updateStatus = {
            class: "danger",
            msg: res.response.data.message
          };
        }
        this.loading = false;
      },
      async () => {
        await this.GET_ALL_ARCHIVED_PROJECTS();
      }
    );
  }
  async HandleDeletePorject(project: Project) {
    this.confirm(
      (this as any).$t("projects.projectTable.confirmationModal.body", {
        name: project.name
      }),
      async () => {
        this.loading = false;
        let deleteStatus = await this.DELETE_PROJECT(project.id);
        if (deleteStatus === 204) {
          this.updateStatus = {
            class: "success",
            msg: this.$t("projects.statusMsg.deleteSuccess").toString()
          };
          await this.GET_ALL_ARCHIVED_PROJECTS();
          this.checkCurrentOpen();
          this.loading = false;
        } else {
          this.updateStatus = {
            class: "danger",
            msg: deleteStatus
          };
          await this.GET_ALL_ARCHIVED_PROJECTS();
        }
      }
    );
  }

  handleOpen(row: any) {
    row.toggleDetails();
    if (!row.detailsShowing) {
      const currentProjectId = row.item.id;
      const mainProjectId = row.item.parent?.id;

      if (!mainProjectId) {
        this.updateMainProjectOpen({
          id: currentProjectId,
          name: ""
        });
      }
      if (mainProjectId) {
        this.updateSubProjectOpen({
          id: currentProjectId,
          name: ""
        });
      }
    }
  }
}
